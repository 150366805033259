import React, { Component, Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import BannerH4 from '../components/share/banner/bannerH4'
import BannerH6 from '../components/share/banner/bannerH6'
import Boxes from '../components/share/boxes'
import BrandBanner from '../components/share/brandBanner'
import TypeFormButton from '../components/share/typeFormButton'
import ContentWrapper from '../components/share/contentWrapper'
import FeatureBox from '../components/share/featureBox'
import FooterBox from '../components/share/footerBox'
import GreyBackground from '../components/share/greyBackground'
import ImageBackground from '../components/share/imageBackground'
import IntroRow from '../components/share/introRow'
import IntroVideo from '../components/share/introVideo'
import Layout from '../components/layout'
import Quote from '../components/share/quote'
import SpeakToTeam from '../components/share/speakToTeam'

import servicesData from '../data/services'

import customMedia from '../utils/customMedia'

const query = graphql`
  query {
    header: file(relativePath: { eq: "services/header.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    campaign: file(relativePath: { eq: "services/campaign.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    newspaper: file(relativePath: { eq: "services/newspaper.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    digital: file(relativePath: { eq: "services/digital.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    print: file(relativePath: { eq: "services/print.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    bookings: file(relativePath: { eq: "services/bookings.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    artworks: file(relativePath: { eq: "services/artworks.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    deadlines: file(relativePath: { eq: "services/deadlines.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    johnMcManus: file(relativePath: { eq: "brandAgentIcon/john_mcmanus.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    timer: file(relativePath: { eq: "services/timer.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    publications: file(relativePath: { eq: "services/publications.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    services: file(relativePath: { eq: "services/services.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    socialPhone: file(relativePath: { eq: "services/socialPhone.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    internalPrint: file(relativePath: { eq: "services/internalPrint.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    photography: file(relativePath: { eq: "services/photography.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    agentpromo: file(relativePath: { eq: "services/agentpromo.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    leadGen: file(relativePath: { eq: "services/leadGen.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    plugged: file(relativePath: { eq: "services/plugged.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

class ServicesPage extends Component {
  _scrollToSection = (index, title) => {
    const service = title.toLowerCase().replace(/\s/, '')
    this[service].scrollIntoView({ block: 'start', behavior: 'smooth' })
  }

  render() {
    return (
      <Layout>
        <StaticQuery
          query={query}
          render={data => (
            <Fragment>
              <ImageBackground
                background={data.header.childImageSharp.fluid}
                opacity="0.6"
                skew
              >
                <ContentWrapper style={{ marginTop: 0 }}>
                  <HeaderTitle>
                    Built for speed. Powering multi-millions in campaigns
                    weekly.
                  </HeaderTitle>

                  <HeaderBody>
                    Powerful, fast and easy to use,{' '}
                    <strong>Real Estate Marketing platform </strong>
                    that powers your agency and your{' '}
                    <strong>personal marketing</strong> campaigns from digital
                    and print providers Australia wide. Built for professional
                    agencies, just like yours.
                  </HeaderBody>

                  <HeaderLink>
                    Start producing marketing that matters{' '}
                    <Icon icon={['fal', 'arrow-down']} />
                  </HeaderLink>
                </ContentWrapper>

                <HeaderCampaignImg
                  fluid={data.campaign.childImageSharp.fluid}
                  style={{ position: 'absolute' }}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </ImageBackground>
              <ContentWrapper style={{ alignItems: 'center' }}>
                <BannerH4>
                  All of <strong>your campaigns,</strong> and all of{' '}
                  <strong>your staff</strong>, in one professional platform
                  built for <strong>professional agents.</strong>
                </BannerH4>
                <BannerH6>
                  Cloud hosted marketing and business management, be better
                  organised. Sell more effectively.
                </BannerH6>
              </ContentWrapper>
              <ContentWrapper direction="row" style={{ alignItems: 'center' }}>
                <FeatureBox
                  image={data.newspaper.childImageSharp.fluid}
                  contentStyle={{ height: '90px' }}
                  title="Newspaper"
                  subTitle="Find leads, win more listings"
                  content="Use the industries most advanced reporting and digital proposal software on the market to wow sellers."
                  link="#"
                />
                <FeatureBox
                  image={data.digital.childImageSharp.fluid}
                  contentStyle={{ height: '90px' }}
                  title="Digital"
                  subTitle="Marketing Campaign Manager"
                  content="Tools that make you more efficient, allowing you to sell more houses with less overheads and resources."
                  link="#"
                />

                <FeatureBox
                  image={data.print.childImageSharp.fluid}
                  contentStyle={{ height: '90px' }}
                  title="Print"
                  subTitle="Business Management Tools"
                  content="Keep complete end-to-end oversight across your business and staff’s marketing and productivity."
                  link="#"
                />
              </ContentWrapper>
              <ContentWrapper>
                <Quote
                  agentName="John McManus"
                  agentImage={data.johnMcManus.childImageSharp.fluid}
                  agencyName="LJHooker"
                  imgStyle={{ width: '60px' }}
                  title="Principal/Director"
                  desc={`“They’ve got young people in the business that are enthusiastic with great, new and fresh ideas. I’ve put all my faith in the guys at realhub. If you’re going to start looking at becoming a local unique company talk to the guys at realhub, it’ll change your business.”`}
                />
              </ContentWrapper>
              <ContentWrapper>
                <IntroRow
                  image={data.timer.childImageSharp.fluid}
                  title="Get up and running fast"
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>So easy, you’ll wonder why you waited</strong>
                      </Paragraph>

                      <Paragraph>
                        There’s a lot that goes in to marketing a property,
                        across hundreds of suppliers, technical specifications,
                        deadlines etc. Realhub services clients across the
                        entire Australian marketplace and our existing
                        relationships with these suppliers means set up is a
                        breeze.
                      </Paragraph>
                      <Paragraph>
                        You don’t have to worry about anything, we’ll guide you
                        through the entire process.
                      </Paragraph>
                      <Paragraph>
                        Our onboarding team has implemented hundred’s of
                        agencies, thousands of newspapers and thousands of
                        providers. If we don’t already have them, we can get
                        them faster than you can build a brochure.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>
              <ContentWrapper style={{ alignItems: 'center' }}>
                <BannerH4>
                  Get <strong>more services</strong> in{' '}
                  <strong>one place</strong>, with a{' '}
                  <strong>professional marketing platform</strong> that enables
                  growth.
                </BannerH4>
                <BannerH6>
                  The power to choose from any real estate supplier, across
                  every marketing product you could ever need, from one place,
                  with one snapshot of
                </BannerH6>
              </ContentWrapper>
              <ContentWrapper>
                <Boxes
                  name="services"
                  items={servicesData}
                  rowItems="4"
                  color="black"
                  iconSize="50%"
                  boxMargin="10px"
                  iconLength="40%"
                  background="#f2f2f2"
                  adjustHeight="75px"
                  clickBox={this._scrollToSection}
                />
              </ContentWrapper>
              <ContentWrapper style={{ alignItems: 'center' }}>
                <BannerH4>
                  Experience <strong>less stress </strong>
                  during bookings, artworks and ordering.{' '}
                  <strong>Anyone is an expert </strong>
                  with Realhub.
                </BannerH4>
              </ContentWrapper>
              <ImageBackground
                height="700px"
                opacity="0"
                background={data.publications.childImageSharp.fluid}
              >
                <ContentWrapper
                  style={{ margin: '30px 0' }}
                  contentRef={el => (this.newspaper = el)}
                >
                  <IntroRow
                    image={data.newspaper.childImageSharp.fluid}
                    descStyle={{ color: '#404041' }}
                    descWidth="60%"
                    title="Covering Australia for real estate publications."
                    content={
                      <Fragment>
                        <Paragraph>
                          <strong>
                            Newspaper bookings, artworks and communication
                            Australia wide.
                          </strong>
                        </Paragraph>
                        <Paragraph>
                          Between us and the newspaper is an instant digital
                          link. Book ads faster, build artworks easier and never
                          worry about complicated technical specs or delivery
                          methods. Everything is right in your campaign
                          dashboard alongside the rest of your marketing.
                        </Paragraph>
                        <Paragraph>
                          Our staff have extensive knowledge on newsprint and
                          are always happy to help if you’ve got any questions,
                          deadline extensions or anything else really.
                        </Paragraph>
                        <Paragraph>
                          <strong>
                            We offer professional proofing and pagination, or
                            allow a fully hands off do it yourself approach.
                          </strong>
                        </Paragraph>
                        <Paragraph>
                          If you’re already managing a group of offices, booking
                          together we offer an easy to use and understand
                          booking, artwork and pagination platform. But if you’d
                        </Paragraph>
                      </Fragment>
                    }
                  />
                </ContentWrapper>
              </ImageBackground>
              <ContentWrapper direction="row">
                <FeatureBox
                  image={data.bookings.childImageSharp.fluid}
                  title="Booking"
                  subTitle="Manage and track all bookings, across every publication."
                  content="Our platform makes booking everything from single ads, to multi week campaigns, liftovers and promo advertising easier than ever."
                />
                <FeatureBox
                  image={data.artworks.childImageSharp.fluid}
                  title="Artworks"
                  subTitle="Build the artwork, for the approriate paper, without the guesswork."
                  content="Always use the right artwork for the right publication without worrying about baseplates, newspaper specs and bleeds, positioning and see them all paginated."
                />

                <FeatureBox
                  image={data.deadlines.childImageSharp.fluid}
                  title="Deadlines"
                  subTitle="Make sure you always place the ad on time with deadline reminders."
                  content="Ever missed a paper deadline? With our dedicated newspaper team we can follow up on artworks and bookings to ensure you don’t miss a crucial booking."
                />
              </ContentWrapper>

              <ContentWrapper>
                <IntroRow
                  reverse
                  image={data.leadGen.childImageSharp.fluid}
                  descWidth="50%"
                  title="More than just a house sale, it’s a lead-gen tool."
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          Attract new sellers with your marketing.
                        </strong>
                      </Paragraph>
                      <Paragraph>
                        Not only will passive buyers increase your chances of an
                        exceptional sales price achieved for your vendor, that
                        same passive buyer who wasn’t in the market except for
                        the right house is now a seller that needs an agent.
                      </Paragraph>
                      <Paragraph>
                        Sellers do their research online and in newsprint,
                        looking for who the best option to sell their house is,
                        so having a strong presence in the newspaper is a must
                        to attract more sellers.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <GreyBackground>
                <ContentWrapper contentRef={el => (this.services = el)}>
                  <IntroRow
                    descWidth="50%"
                    image={data.services.childImageSharp.fluid}
                    title="One single marketplace, for every supplier, every service"
                    content={
                      <Fragment>
                        <Paragraph>
                          <strong>
                            Learn one system, order everything. Your entire
                            office’s marketing in one place.
                          </strong>
                        </Paragraph>
                        <Paragraph>
                          We’ve built relationships with almost every real
                          estate supplier on the market. Through years of
                          experience in our own print/digital offerings our
                          platform is able to provide tools for providers to
                          keep you up to date with your order statuses.
                        </Paragraph>

                        <Paragraph>
                          With some suppliers offering API integrations and up
                          to the minute information on order progress, and even
                          more providers accepting and receiving orders
                          instantly via webhook or email orders you’ll be
                          confident and informed on every order you make for a
                          campaign in one easy to digest space.
                        </Paragraph>
                      </Fragment>
                    }
                  />
                </ContentWrapper>
              </GreyBackground>

              <ContentWrapper contentRef={el => (this.socialmedia = el)}>
                <IntroRow
                  descWidth="50%"
                  image={data.socialPhone.childImageSharp.fluid}
                  title="Agents who don’t embrace social media are doomed to fail."
                  link="/social-media"
                  linkText="Read about our Social Media Platform"
                  content={
                    <Fragment>
                      <Paragraph>
                        Millenials make up{' '}
                        <strong>66 percent of the market </strong>
                        for first-time homebuyers.{' '}
                        <strong>99% of buyers</strong> use the internet looking
                        to purchase a new house. There are over{' '}
                        <strong> 25 million Australians </strong>
                        and over 15 million of them use Facebook and Youtube.
                      </Paragraph>
                      <Paragraph>
                        It’s not enough to just list your property on real
                        estate portals, passive buyers aren’t there.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper
                style={{ margin: '30px auto' }}
                contentRef={el => (this.video = el)}
              >
                <IntroRow
                  descWidth="50%"
                  title="The best video agency in the industry, plus any existing suppliers."
                  linkText="Visit Real Studios Website Now"
                  link="https://www.realstudios.com.au/"
                  content={
                    <Paragraph>
                      We might be a little biased, but our real estate video
                      team is one of the best across the industry. If you’re
                      fortunate enough to work within the Northern Beaches or
                      Lower North Shore, you’ll be able to take advantage of the
                      team at Real Studios and improve your video marketing.
                      View the Real Studios showreel below to see how you could
                      be marketing your own agency tomorrow.
                    </Paragraph>
                  }
                />
                <IntroVideo id="276146080" />
              </ContentWrapper>
              <ContentWrapper style={{ margin: '30px auto' }}>
                <IntroRow
                  descWidth="50%"
                  content={
                    <Fragment>
                      <Paragraph
                        style={{ fontFamily: 'Caveat', fontSize: '24px' }}
                      >
                        What if I’ve already got my own video supplier?
                      </Paragraph>
                      <Paragraph>
                        Alternatively, Australia wide we have a wide network of
                        equalliy high quality video providers we can offer, or
                        you can bring your own.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ImageBackground
                background={data.internalPrint.childImageSharp.fluid}
                height="500px"
                opacity="0.6"
              >
                <ContentWrapper
                  style={{ margin: '30px auto' }}
                  contentRef={el => (this.print = el)}
                >
                  <IntroRow
                    descWidth="60%"
                    title={
                      <Fragment>
                        Our own internal print operation,
                        <strong>and every other printer you need.</strong>
                      </Fragment>
                    }
                    content={
                      <Paragraph>
                        We service the Northern Beaches with high quality, fast
                        turnaround print solutions. For everyone else we provide
                        <strong>high quality print ready artworks </strong>
                        to every real estate printer in the industry. So every
                        marketing campaign you undertake, it’s print perfect. No
                        matter where.
                      </Paragraph>
                    }
                  />
                </ContentWrapper>
              </ImageBackground>
              <ContentWrapper contentRef={el => (this.photography = el)}>
                <IntroRow
                  reverse
                  descWidth="60%"
                  image={data.photography.childImageSharp.fluid}
                  title="Photography from the best in the business."
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          We’re not here to get in the way of existing
                          relationships, bring your own photographer, or find a
                          new one. (Even just a temporary replacement).
                        </strong>
                      </Paragraph>
                      <Paragraph>
                        Photography quality is entirely subjective. That’s why
                        we don’t try and pidgeonhole ourselves into one style
                        and prefer to partner with the best companys in the
                        entire industry have them deliver the quality you’re
                        after, with no friction.
                      </Paragraph>
                      <Paragraph>
                        Photographers can upload your photography straight to
                        your realhub campaign.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper contentRef={el => (this.agentmarketing = el)}>
                <IntroRow
                  descWidth="60%"
                  image={data.agentpromo.childImageSharp.fluid}
                  title="It’s not enough to market property. Million dollar agents never stop promoting themselves."
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          We’re not just focused on property sales, getting your
                          name out there, exploding your personal brands
                          recognition and building a consistent funnel of leads
                          into the business is where marketing yourself comes
                          in.
                        </strong>
                      </Paragraph>
                      <Paragraph>
                        Tools and templates built sepcifically to market you and
                        your agency, in the most accessible marketplace online,
                        with the ability to shop around for the best price and
                        make sure your marketing is not only cost effective, but
                        has a great ROI (return on investment).
                      </Paragraph>
                      <Paragraph>
                        Past marketing yourself and your office the standard kit
                        for an agent, from flags, to business cards, pointer
                        boards and a-frames are all easier to order from Realhub
                        where all your data already sits, no back and forward
                        proofing just built it right yourself, the first time.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ImageBackground
                background={data.plugged.childImageSharp.fluid}
                height="500px"
                opacity="0"
              >
                <ContentWrapper
                  style={{ margin: '30px auto' }}
                  contentRef={el => (this.web = el)}
                >
                  <IntroRow
                    descWidth="60%"
                    linkText="Read more about the marketplace"
                    title={
                      <Fragment>
                        Finally, all your suppliers, <br />
                        <strong>plugged in together.</strong>
                      </Fragment>
                    }
                    content={
                      <Fragment>
                        <Paragraph>
                          It doesn’t have to take{' '}
                          <strong>28 different logins</strong> to{' '}
                          <strong>28 different systems.</strong> Not anymore.
                          We’re currently plugged into over 400 major Real
                          Estate suppliers, and more and more are becoming
                          available daily.
                        </Paragraph>
                        <Paragraph>
                          If you’ve got your own suppliers and they aren’t
                          already available, let us know and{' '}
                          <strong>we’ll get them set up ASAP</strong> so you can
                          experience the benefits of getting it all in one spot.
                        </Paragraph>
                      </Fragment>
                    }
                  />
                </ContentWrapper>
              </ImageBackground>
              <ContentWrapper>
                <FlexColumn style={{ alignItems: 'center' }}>
                  <BannerH4>
                    Our <strong>loyal team</strong> of{' '}
                    <strong>Australia’s leading</strong> real estate agencies,
                    working <strong>hand in hand</strong> to help build the{' '}
                    <strong>future of real estate.</strong>
                  </BannerH4>
                  <BannerH6 style={{ marginBottom: 0 }}>
                    Input from the largest and smallest real estate businesses
                    in Australia is helping to build the most effective platform
                    for professional agents.
                  </BannerH6>
                  <TypeFormButton />
                </FlexColumn>
              </ContentWrapper>

              <ContentWrapper>
                <BrandBanner />
              </ContentWrapper>

              <ContentWrapper>
                <SpeakToTeam
                  uid="WpgxlK"
                  avatar="https://labs-assets.typeform.com/cui/avatars/WpgxlK/cde0f362a9e74bff7bfc.png"
                />
              </ContentWrapper>
              <ContentWrapper direction="row">
                <FooterBox name="migration" />

                <FooterBox name="feetUp" />
              </ContentWrapper>
            </Fragment>
          )}
        />
      </Layout>
    )
  }
}

export default ServicesPage

const HeaderTitle = styled.h1`
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    max-width: 680px;
    font-size: 53px;
  `};
`
const HeaderCampaignImg = styled(Img)`
  position: absolute;
  overflow: hidden;
  width: 300px;
  height: 300px;
  bottom: -100px;
  right: -40px;

  ${customMedia.between('mobile', 'tablet')`
    width: 550px;
    height: 550px;
    bottom: -220px;
    right: -75px;
  `};

  ${customMedia.greaterThan('desktop')`
    width: 550px;
    height: 550px;
    bottom: -250px;
    right: -140px;
  `};
`

const HeaderBody = styled.p`
  font-size: 14px;
  font-weight: 300;
  font-family: Open Sans;
  line-height: 1.5;
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    max-width: 450px;
  `};
`
const HeaderLink = styled.a`
  font-family: Caveat;
  color: #02b7e2;
  font-size: 14px;
  letter-spacing: 1px;
  margin-bottom: 20px;

  ${customMedia.greaterThan('desktop')`
    font-size: 18px;
    margin-bottom: 10px;
  `};
`
const Icon = styled(FontAwesomeIcon)`
  font-size: 14px;
  margin-left: 5px;
`

const Paragraph = styled.p`
  font-family: Open Sans;
  margin-bottom: 30px;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
